import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "dispatchx frame.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
      <div className="hero-applications">
            <div className="container">
              <div className='column'>
                <div>
                <div className="main-text"> Dispatch <span>X.</span></div>
                <div className="sub-text">  The ideal help for the driver.</div>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className='child'>
                  <h3> Always optimal.</h3>
                  <p>
                  Our routes dynamically adapt to the driver’s choices and are able to incorporate any last-minute changes in the pickups. 
                  The routes are optimized for any city- and vehicle-specific restrictions.
                  </p>
                </div>
                <div className='child right'>
                  <h3> Effortless collaboration. </h3>
                  <p>
                  Was a container bin not placed outside in time, are you stuck in traffic, or is there another problem? 
                  Communicate it easily through the application to the administration, other drivers or customers. 
                  </p>
                </div>
                <div className='child'>
                  <h3> All information a click away. </h3>
                  <p>
                  Make a note of the relevant pickup information at each address. Note what type of waste was 
                  collected, make photo’s, and scan the containers in case these are sensor-equipped. All data is immediately 
                  transferred to our algorithms for analysis. 
                  </p>
                </div>
            </div>
          </div>
        </div>
    )
}

export default Hero

