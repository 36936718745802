import React from "react"
import "../styles/styles.scss"

import Header from "../components-en/header"
import Demo from "../components-en/demo"
import Footer from "../components-en/footer"

import Hero from "../dispatchx/hero-en"
import Features from "../dispatchx/features-en"

import {Helmet} from 'react-helmet'

const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs - DispatchX. </title>
        <description>DispatchX - the app for the driver.</description>
      </Helmet>
      <Header />
      <Hero />
      <Features />
      <Demo />
      <Footer />
    </div>
)

export default IndexPage
