import React from "react"
import { FaInfo, FaRegCommentDots, FaRoute} from "react-icons/fa"

const Features = () => {
    return(
        <div className="features-applications">
        <div className="container">
        <div className="row-feat header">
                <div className="column-feat header">
                <div className="main-text"> Features </div>
                <div className="sub-text"> Select the components that fit your processes.  </div>
                </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">Routes.</div>
                    <div> <FaRoute size={50} color={"#7FACD6"}/> </div>
                    <div>Always the optimal route.</div>
                    <div>Follow the progress through the map-based process monitor. </div>
                    <div>Insight into time window locations and last-minute pickup changes. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Communication.</div>
                    <div> <FaRegCommentDots size={50} color={"#7FACD6"}/> </div>
                    <div>Customer not present or wrong waste presented? Easily make a note of this for the administration to resolve. </div>
                    <div>Last-minute changes during the day are automatically incorporated in the routes. </div>
                    <div>Communicate with other drivers.</div>
                </div>
                <div className="column-feat">
                    <div className="title">Information.</div>
                    <div> <FaInfo size={50} color={"#7FACD6"}/> </div>
                    <div>Tick off visited locations and make notes on the collected waste. </div>
                    <div>Scan containers or make pictures for faster processing. </div>
                    <div>Note any problems you encounter</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Features